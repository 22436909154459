<template>
	<div class="map-container">
		<div ref="map" class="map"></div>
		<div class="credits small">&copy;swisstopo</div>
		<div id="location-marker"></div>
	</div>
</template>

<script>
import axios from 'axios'
import 'ol/ol.css';
import Map from 'ol/Map';
import Projection from 'ol/proj/Projection';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import View from 'ol/View';
import {addCoordinateTransforms, addProjection, transform} from 'ol/proj';
import {Icon, Circle, Text, Style, Stroke, Fill} from 'ol/style';
import {Cluster, Vector as VectorSource} from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';

const projection = new Projection({
	code: 'EPSG:21781',
	extent: [485869.5728, 76443.1884, 837076.5648, 299941.7864],
	units: 'm',
})
addProjection(projection)

const styleCache = {};
const colors = {
	primary: '#06434D',
	primaryOpacity: 'rgba(150,140,27,0.7)'
}

const perimeterVs = new VectorSource()
const generalRouteVs = new VectorSource()
const routeVs = new VectorSource()
const poiVs = new VectorSource()
const poiClusterSource = new Cluster({
	distance: 24,
	minDistance: 10,
	source: poiVs,
});

export default {
	props: {},
	data: () => ({
		map: false,
		active: 'topo',
		zoom: 4.5,
		q: null,
		showResults: false,
		results: null,
		inputName: null,
		selected: null,
		language: 'de',
		initialItem: {
			'type': null,
			'id': null
		},
		initialItemSelected: false,
		layers: {
			'topo': null,
			'ortho': null,
			'hiking': null,
			'transport': null,
			'poi': null,
			'route': null,
			'perimeter': null,
		},
		defaultOpacity: {
			'topo': 1,
			'ortho': 1,
			'hiking': 0.5,
			'transport': 1,
			'poi': 1,
			'route': 1,
			'perimeter': 0.3
		}
	}),
	methods: {
		setLanguage(lang) {
			this.language = lang
		},
		hideLayer(layer) {
			this.layers[layer].setOpacity(0)
			if (layer == 'route') {
				this.layers['generalRoute'].setOpacity(0)
			}
		},
		showLayer(layer) {
			this.layers[layer].setOpacity(this.defaultOpacity[layer])
			if (layer == 'route') {
				this.layers['generalRoute'].setOpacity(this.defaultOpacity[layer])
			}
		},
		selectFeature(e) {
			let obj = this
			let map = this.map
			let view = map.getView()
			
			map.forEachFeatureAtPixel(e.pixel, function(feature) {
				obj.deselectFeature()
				let properties = feature.getProperties()
				
				// if cluster
				if (properties.features) {
					let clusterFeatures = properties.features
					if (clusterFeatures.length > 1) {
						let coor = feature.getGeometry().getCoordinates()
						let zoom = view.getZoom()
						view.setCenter(coor)
						view.setZoom(zoom+1)
					}
					else {
						let poiFeature = clusterFeatures[0]
						let poiProperties = poiFeature.getProperties()
						obj.selected = poiFeature
						obj.fetchFeatureInfo(poiProperties)
						poiFeature.set('highlighted', true)
					}
				}
				else {
					obj.selected = feature
					obj.fetchFeatureInfo(properties)
					
					if (properties.type == 'route') {
						feature.set('highlighted', true)
					}
				}
				return true
			}, {hitTolerance: 6})
		},
		deselectFeature() {
			if (this.selected !== null) {
				this.selected.set('highlighted', false)
				this.selected = null;
				this.$parent.$refs.infoBox.close()
			}
		},
		onPointerMove(e) {
			// change mouse cursor when over marker
			let pixel = this.map.getEventPixel(e.originalEvent)
			//let hit = this.map.hasFeatureAtPixel(pixel)
			let hit = this.map.hasFeatureAtPixel(pixel, {
				layerFilter: function (layer) {
					return layer.get('id') !== 'perimeter-layer';
				}
			})
			this.map.getTarget().style.cursor = hit ? 'pointer' : ''
		},
		onMoveend() {
			let view = this.map.getView()
			let size = this.map.getSize();
			let bbox = view.calculateExtent(size)
			this.fetchPerimeters(bbox, size[0], size[1])
			
			let newZoom = view.getZoom()
			if (this.zoom != newZoom) {
				this.zoom = newZoom
			}
		},
		/*
		searchOnInput() {
			this.results = null
			this.showResults = false
			if (this.q.length > 2) {
				this.searchLocation()
			}
		},
		searchLocation() {
			axios.get('https://api3.geo.admin.ch/rest/services/api/SearchServer?type=locations&origins=gazetteer&searchText='+ this.q).then((result) => {
				this.results = result.data.results
				this.showResults = true
			})
		},
		*/
		fetchPerimeters(bbox, width, height) {
			let url = 'https://sdi.cde.unibe.ch/geoserver/ourheritage/wms?service=WMS&version=1.1.0&request=GetMap&layers=ourheritage%3Awelterbe_perimeter&srs=EPSG%3A21781&styles=&format=geojson'
			url += '&bbox='+ bbox
			url += '&width='+ width
			url += '&height='+ height
			
			axios.get(url).then((result) => {
				perimeterVs.clear()
				perimeterVs.addFeatures(
					new GeoJSON().readFeatures(result.data)
				)
			})
		},
		fetchPois() {
			axios.get('poi/list?frontend='+ this.getFrontend()).then((result) => {
				poiVs.addFeatures(
					new GeoJSON().readFeatures(result.data)
				)
				this.selectInitialItem('poi', poiVs)
			})
		},
		fetchRoutes() {
			axios.get('route/list?type=detail&frontend='+ this.getFrontend()).then((result) => {
				routeVs.addFeatures(
					new GeoJSON().readFeatures(result.data)
				)
				this.selectInitialItem('route', routeVs)
			})
			axios.get('route/list?type=general&frontend='+ this.getFrontend()).then((result) => {
				generalRouteVs.addFeatures(
					new GeoJSON().readFeatures(result.data)
				)
				this.selectInitialItem('route', generalRouteVs)
			})
		},
		getFrontend() {
			let container = document.getElementById("heritage-map")
			return container.getAttribute('frontend')
		},
		fetchFeatureInfo(properties) {
			let obj = this;
			let path = properties.type +'/'+ properties.id +'?fullDescription=1&lang='+ this.language
			axios.get(path).then((result) => {
				obj.$parent.$refs.infoBox.setInfo(result.data)
			})
		},
		selectInitialItem(type, vs) {
			if (!this.initialItemSelected && this.initialItem.type == type) {
				let view = this.map.getView()
				let features = vs.getFeatures()
				
				for (let i = 0, ii = features.length; i < ii; i++) {
					let feature = features[i]
					let properties = feature.getProperties()
					
					if (properties.id == this.initialItem.id) {
						this.initialItemSelected = true
						this.selected = feature
						this.fetchFeatureInfo(properties)
						
						if (type == 'heritage') {
							this.selectedHeritage(feature)
						}
						else {
							view.fit(feature.getGeometry(), {padding: [60, 60, 60, 60]})
						
							if (properties.type == 'route') {
								feature.set('highlighted', true)
							}
						
							if (properties.type == 'poi') {
								feature.set('highlighted', true)
							}
						}
						break
					}
				}
			}
		},
		setInitalItem() {
			let container = document.getElementById("heritage-map")
			let attr = container.getAttribute('show')
			if (attr && attr != "") {
				let props = attr.split('-')
				this.initialItem.type = props[0]
				this.initialItem.id = props[1]
			}
		}
	},
	mounted() {	
		this.map = new Map({
			target: this.$refs['map'],
			layers: [
				this.layers.ortho = new TileLayer({
					id: "ortho-layer",
					opacity: 0,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.swisstopo.swissimage',
							'FORMAT': 'image/jpeg',
						},
						serverType: 'mapserver',
					})
				}),
				this.layers.topo = new TileLayer({
					id: "topo-layer",
					opacity: 1,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.swisstopo.pixelkarte-grau',
							'FORMAT': 'image/jpeg',
						},
						serverType: 'mapserver',
					})
				}),
				this.layers.perimeter = new VectorLayer({
					id: "perimeter-layer",
					minZoom: 3,
					opacity: this.defaultOpacity.perimeter,
					source: perimeterVs,
					style: function() {
						return new Style({
							fill: new Fill({
								color: colors.primaryOpacity,
							})
						})
					}
				}),
				this.layers.hiking = new TileLayer({
					id: "hiking-layer",
					opacity: 0,
					minZoom: 5,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.swisstopo.swisstlm3d-wanderwege',
						}
					})
				}),
				this.layers.route = new VectorLayer({
					id: "route-layer",
					minZoom: 3,
					source: routeVs,
					style: function(feature) {
						return routeStyle(feature)
					}
				}),
				this.layers.generalRoute = new VectorLayer({
					id: "general-route-layer",
					source: generalRouteVs,
					style: function(feature) {
						return routeStyle(feature)
					}
				}),
				this.layers.transport = new TileLayer({
					id: "transport-layer",
					opacity: 0,
					minZoom: 8,
					source: new TileWMS({
						url: 'https://wms.geo.admin.ch/',
						params: {
							'LAYERS': 'ch.bav.haltestellen-oev'
						}
					})
				}),
				this.layers.poi = new VectorLayer({
					id: "poi-layer",
					minZoom: 3,
					source: poiClusterSource,
					style: function (feature) {
						let features = feature.get('features')
						
						if (features[0].get('highlighted')) {
							let style = styleCache['poiSelected'];
							let properties = features[0].getProperties()
							if (!style) {
								style = new Style({
									image: new Icon({
										anchor: [20, 58],
										anchorXUnits: 'pixels',
										anchorYUnits: 'pixels',
										src: properties.marker
									})
								})
								styleCache['poiSelected'] = style;	
							}
							return style
						}
						else {
							let size = features.length
							let style = styleCache[size];
							if (!style) {
								let radius = size + 18;
								if (size > 18) {
									radius = (size * 0.2) + 11;
								}
								style = new Style({
									image: new Circle({
										radius: radius,
										stroke: new Stroke({
											color: '#fff',
											width: 2
										}),
										fill: new Fill({
											color: colors.primary,
										}),
									}),
									text: new Text({
										text: size.toString(),
										font: radius +'px sans-serif',
										fill: new Fill({
											color: '#fff',
										})
									})
								})
								styleCache[size] = style;	
							}
							return style;
						}
					}
				})
			],
			view: new View({
				projection: projection,
				center: transform([8.0, 46.53], 'EPSG:4326', 'EPSG:21781'),
				zoom: this.zoom,
				minZoom: 4.5,
				maxZoom: 12,
				enableRotation: false
			}),
		});
		
		this.map.on('pointermove', this.onPointerMove)
		this.map.on('click', this.selectFeature)
		this.map.on('moveend', this.onMoveend)
		
		this.setInitalItem()
		this.fetchPois()
		this.fetchRoutes()
	}
}

function routeStyle(feature) {
	let color = feature.getProperties().color;
	if (feature.get('highlighted')) {
		return new Style({
			stroke: new Stroke({
				color: color,
				width: 12
			})
		})
	}
	else {
		return new Style({
			stroke: new Stroke({
				color: color,
				width: 7
			})
		})
	}
}

// We also declare EPSG:21781/EPSG:4326 transform functions. These functions
// are necessary for the ScaleLine control and when calling ol/proj~transform
// for setting the view's initial center (see below).

addCoordinateTransforms(
	'EPSG:4326',
	projection,
	function (coordinate) {
		return [
			WGStoCHy(coordinate[1], coordinate[0]),
			WGStoCHx(coordinate[1], coordinate[0]),
		];
	},
	function (coordinate) {
		return [
			CHtoWGSlng(coordinate[0], coordinate[1]),
			CHtoWGSlat(coordinate[0], coordinate[1]),
		];
	}
);

/*
 * Swiss projection transform functions downloaded from
 * https://www.swisstopo.admin.ch/en/knowledge-facts/surveying-geodesy/reference-systems/map-projections.html
 */

// Convert WGS lat/long (� dec) to CH y
function WGStoCHy(lat, lng) {
  // Converts degrees dec to sex
  lat = DECtoSEX(lat);
  lng = DECtoSEX(lng);

  // Converts degrees to seconds (sex)
  lat = DEGtoSEC(lat);
  lng = DEGtoSEC(lng);

  // Axillary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process Y
  const y =
    600072.37 +
    211455.93 * lng_aux -
    10938.51 * lng_aux * lat_aux -
    0.36 * lng_aux * Math.pow(lat_aux, 2) -
    44.54 * Math.pow(lng_aux, 3);

  return y;
}

// Convert WGS lat/long (� dec) to CH x
function WGStoCHx(lat, lng) {
  // Converts degrees dec to sex
  lat = DECtoSEX(lat);
  lng = DECtoSEX(lng);

  // Converts degrees to seconds (sex)
  lat = DEGtoSEC(lat);
  lng = DEGtoSEC(lng);

  // Axillary values (% Bern)
  const lat_aux = (lat - 169028.66) / 10000;
  const lng_aux = (lng - 26782.5) / 10000;

  // Process X
  const x =
    200147.07 +
    308807.95 * lat_aux +
    3745.25 * Math.pow(lng_aux, 2) +
    76.63 * Math.pow(lat_aux, 2) -
    194.56 * Math.pow(lng_aux, 2) * lat_aux +
    119.79 * Math.pow(lat_aux, 3);

  return x;
}

// Convert CH y/x to WGS lat
function CHtoWGSlat(y, x) {
  // Converts military to civil and to unit = 1000km
  // Axillary values (% Bern)
  const y_aux = (y - 600000) / 1000000;
  const x_aux = (x - 200000) / 1000000;

  // Process lat
  let lat =
    16.9023892 +
    3.238272 * x_aux -
    0.270978 * Math.pow(y_aux, 2) -
    0.002528 * Math.pow(x_aux, 2) -
    0.0447 * Math.pow(y_aux, 2) * x_aux -
    0.014 * Math.pow(x_aux, 3);

  // Unit 10000" to 1 " and converts seconds to degrees (dec)
  lat = (lat * 100) / 36;

  return lat;
}

// Convert CH y/x to WGS long
function CHtoWGSlng(y, x) {
  // Converts military to civil and to unit = 1000km
  // Axillary values (% Bern)
  const y_aux = (y - 600000) / 1000000;
  const x_aux = (x - 200000) / 1000000;

  // Process long
  let lng =
    2.6779094 +
    4.728982 * y_aux +
    0.791484 * y_aux * x_aux +
    0.1306 * y_aux * Math.pow(x_aux, 2) -
    0.0436 * Math.pow(y_aux, 3);

  // Unit 10000" to 1 " and converts seconds to degrees (dec)
  lng = (lng * 100) / 36;

  return lng;
}

// Convert DEC angle to SEX DMS
function DECtoSEX(angle) {
  // Extract DMS
  const deg = parseInt(angle, 10);
  const min = parseInt((angle - deg) * 60, 10);
  const sec = ((angle - deg) * 60 - min) * 60;

  // Result in degrees sex (dd.mmss)
  return deg + min / 100 + sec / 10000;
}

// Convert Degrees angle to seconds
function DEGtoSEC(angle) {
  // Extract DMS
  const deg = parseInt(angle, 10);
  let min = parseInt((angle - deg) * 100, 10);
  let sec = ((angle - deg) * 100 - min) * 100;

  // Avoid rounding problems with seconds=0
  const parts = String(angle).split('.');
  if (parts.length == 2 && parts[1].length == 2) {
    min = Number(parts[1]);
    sec = 0;
  }

  // Result in degrees sex (dd.mmss)
  return sec + min * 60 + deg * 3600;
}
</script>
