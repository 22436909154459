<template>
	<div class="slider">
		<ul class="slides">
			<li v-for="(image, index) in images" v-bind:key="image" v-bind:class="isActive(index)" class="fade-in">
				<img class="fade-in" v-bind:src="image.url" v-bind:alt="image.alt">
			</li>
		</ul>
		<div class="prev" v-bind:class="showNav()" @click="prev()">
			<i class="fa fa-chevron-left"></i>
		</div>
		<div class="next" v-bind:class="showNav()" @click="next()">
			<i class="fa fa-chevron-right"></i>
		</div>
		<ul class="pagination" v-bind:class="showNav()">
			<li v-for="(image, index) in images" v-bind:key="image" v-bind:class="isActive(index)" @click="setSlide(index)"></li>
		</ul>
	</div>
</template>

<script>
export default {
	props: {
		images: Array
	},
	data() {
		return {
			current: 0
		}
	},
	methods: {
		isActive(index) {
			if (index == this.current) {
				return 'active'
			}
			else {
				return ''
			}
		},
		prev() {
			if (this.current == 0) {
				this.current = this.images.length-1
				
			}
			else {
				this.current = this.current-1
			}
		},
		next() {
			if (this.current == this.images.length-1) {
				this.current = 0
				
			}
			else {
				this.current = this.current+1
			}
		},
		setSlide(index) {
			this.current = index
		},
		showNav() {
			if (this.images.length <= 1) {
				return 'hide'
			}
			else {
				return ''
			}
		}
	}
}
</script>
