<template>
	<div class="header">
		<img src="/img/layout/eut-logo.svg" class="eut-logo">
		<div class="btn-group btn-group-lg" role="group">
			<button type="button" class="btn " v-bind:class="currentLanguage == 'de' ? 'btn-secondary' : 'btn-primary'" @click="setLanguage('de')">DE</button>
			<button type="button" class="btn" v-bind:class="currentLanguage == 'en' ? 'btn-secondary' : 'btn-primary'" @click="setLanguage('en')">EN</button>
		</div>
		<img src="/img/layout/saja-logo.svg" class="saja-logo">
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentLanguage: 'de'
		}
	},
	methods: {
		setLanguage: function (lang) {
			this.currentLanguage = lang
			console.log(this.$root.$refs['map'].setLanguage(lang))
		}
	}
}
</script>
