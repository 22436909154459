<template>
	<div id="app">
		<Map ref="map"/>
		<Header ref="header"/>
		<InfoBox ref="infoBox"/>
	</div>
</template>

<script>
import Header from './components/Header.vue'
import Map from './components/Map.vue'
import InfoBox from './components/InfoBox.vue'

export default {
	name: 'map',
	components: {
		Header,
		Map,
		InfoBox
	}
}
</script>
