<template>
	<div id="map-info-box" v-bind:class="{ active: active }">
		<div class="backdrop" @click="close"></div>
		<div class="card fade-in">
			<div class="close" @click="close" v-bind:title="this.translations.close">
				<i class="fa fa-times"></i>
			</div>
			<div class="row">
				<div class="col-lg-5 header-container">
					<div class="card-header img-header">
						<Slider ref="slider" :images="info.images"/>
					</div>
				</div>
				<div class="col-lg-7">
					<div class="card-body small">
						<div class="h1 card-title margin-bottom" v-html="this.info.title"></div>
						<div class="card-text" v-html="this.info.description"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Slider from '@/components/Slider.vue'

export default {
	components: {
		Slider
	},
	data() {
		return {
			active: false,
			info: {
				id: null,
				type: null,
				slug: null,
				label: null,
				title: null,
				description: null,
				img: {
					url: null,
					alt: null
				},
				images: []
			},
			currentSlide: 0,
			translations: {
				close: 'Close',
				learnMore: 'Learn more'
			}
		}
	},
	methods: {
		setInfo: function (info) {
			this.info = info
			this.open()
		},
		open: function () {
			this.$refs['slider'].current = 0
			this.active = true
		},
		close: function () {
			this.active = false
		},
		setTranslations() {
			var el = document.getElementById('map-translations')
			if (el) {
				this.translations = JSON.parse(el.textContent)
			}
		},
		slideIsActive(index) {
			if (index == this.currentSlide) {
				return 'active'
			}
			else {
				return ''
			}
		},
		setSlide(index) {
			this.currentSlide = index
		}
	},
	mounted() {
		this.setTranslations()
	}
}
</script>
